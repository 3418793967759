import {Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Campaign, Influencer, InfluencerType} from '@app/modules/shared/models';
import {ActivatedRoute, Router} from '@angular/router';
import {InfluencerSharedService} from '@app/services/influencer-shared.service';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {ConfigsService} from '@app/services/configs.service';
import * as routes from '@app/routes';
import {EmailEditorComponent} from '@app/modules/template-generator/email-editor';
import {select, Store} from '@ngrx/store';
import {
  CampaignGet,
  CampaignUpdateError,
  CampaignUpdateSuccess,
  getBulkInfluencers,
  getCampaign,
  getCampaignError,
  getCampaignUpdate,
  getFileUploadProgress,
  getInfluencersProjectSegment,
  getInfluencersTypes,
  getInfluencerSuccess,
  getPaginatedInfluencers,
  getTemplateSuccess,
  getUploadToSignedUrl,
  IAuthenticationState,
  ICampaignState,
  IFileUploadState,
  IInfluencersState,
  InfluencersBulk,
  InfluencersBulkStatus,
  InfluencersDelete,
  InfluencersExport,
  InfluencersListByProjectPaginate,
  InfluencersProjectSegment,
  InfluencersTypeList,
  ISegmentsState,
  ITemplateState,
  ResetInfluencerState,
  TemplatePreview,
  UploadToSignedUrl
} from '@app/stores';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {LoaderService} from '@app/services/loader.service';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataTableDirective} from 'angular-datatables';
import {CreatableSelectComponent} from '@app/_components/creatable-select';
import {InfluencersImportComponent} from '@app/_components/influencers-import/influencers-import.component';
import {ReferralsListComponent} from '@app/_components/referrals-list/referrals-list.component';
import {SegmentListComponent} from '@app/modules/segment/segment-list/segment-list.component';
import {TemplateGeneratorService} from '@app/services/template-generator.service';
import moment from 'moment-timezone';
import {CampaignService} from '@app/services/campaign.service';
import {TemplateApiService} from '@app/services/api/template-api.service';
import {Segment} from '@app/modules/shared/models/segment';
import {SegmentService} from '@app/services/segment.service';
import {SocketService} from '@app/services/socket.service';
import { AwsService } from '@app/services/aws.service';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-add-influencer',
  templateUrl: './add-influencer.component.html',
  styleUrls: ['./add-influencer.component.scss'],
  providers: [EmailEditorComponent]
})
export class AddInfluencerComponent implements OnInit, OnDestroy {
  dtTrigger: Subject<any> = new Subject();
  routes = routes;
  id: number;
  influencers: any;
  influencerTypes: InfluencerType[] = [];
  campId: number;
  audienceType: number;
  downloadTemplatePath = `${environment.apiUrl}/api/v1/influencer/import/template_download/`;
  public step;
  public influencerCount: number;
  public saveCampaignLoading = false;
  private unsubscriber = new Subject();
  private handleClick = false;
  private saveAndExit = false;
  private delete = false;
  public importJobId: number;
  public exportJobId: number;
  loader$: Observable<boolean>;
  @ViewChild('influencerSegment', {static: true}) influencerSegment: CreatableSelectComponent;
  @ViewChild('influencerAdd', {static: true}) influencerAdd: TemplateRef<any>;
  @ViewChild('influencerEdit', {static: true}) influencerEdit: TemplateRef<any>;
  @ViewChild('logsTable', {static: true}) logsTable: TemplateRef<any>;
  @ViewChild('csvDownload', {static: true}) csvDownload: TemplateRef<any>;
  showAll = false;
  selectedInfluencer = null;
  selectedSegment = null;
  segmentsGlobal: any = [];
  filteredSegments: any = [];
  filteredUpdateSegments: any = [];
  influencersLocal: Influencer[] = [];
  campaign: Campaign = null;
  segmentError = '';
  influencerError = '';
  modelOpen = false;
  isTableLoading = false;
  editInfluencerId;
  influencerTypesById = {};
  campaignGetAction = false;
  _object = Object;
  pageNumber = 0;
  segmentIsLoading = false;
  templatesFound = false;
  template: any;
  currentUser: any = {};
  _moment = moment;
  selectedInfluencerForUpdate = null;
  multipleDelete = false;
  multipleUpdate = false;
  exportProcess = false;
  multipleUnsubscribe = false;
  masterSelected: boolean;
  selectAllInfluencer = false;
  pageLength = 0;
  influencerList: Influencer[] = [];
  influencerListIds: any = [];
  segments: Segment[] = [];
  excludedIdList = [];
  includedIdList = [];
  excludedPageList = [];
  includedPageList = [];
  checkedInfluencer: any;
  exportModelRef: any;
  fileResponse: any[] = [];
  blobObject: any;
  fileName: any;
  csvDownloadComplete = false;
  csvUrl: any;
  uniqueImportString: any;
  searchValue:string = '';
  isModelClosed: boolean = false;
  public loaderDelete = new BehaviorSubject<boolean>(false);
  public loaderDelete$ = this.loaderDelete.asObservable();
  public socketSubscription: any;
  public importProgress: any = {
    status: 'started',
    progress: 0
  };
  public exportProgress: any = {
    status: 'started',
    progress: 0
  };
  importData: Array<Object>;
  importDataTableOptions: DataTables.Config = {
    ordering: false,
    processing: true,
    pagingType: 'full_numbers',
    pageLength: 10,
    paging: true,
    searching: true,
    drawCallback: function (setting) {
      // document.querySelector('.custom-table').classList.remove('blur');
      const totalPages = this.api().page.info().pages;
      if (totalPages <= 1) {
        document.querySelector('.dataTables_paginate').classList.add('d-none');
      } else {
        document.querySelector('.dataTables_paginate').classList.remove('d-none');
      }
    }
  };

  public loaderImport = new BehaviorSubject<boolean>(false);
  public loaderImport$ = this.loaderImport.asObservable();

  @HostListener('window:emptyTableCallback', ['$event.detail'])
  emptyTableCallback(detail) {
    if (detail === 'add') {
      this.addInfluencer();
    } else if (detail === 'import') {
      this.importInfluencers();
    } else {
      this.validateSave(false, false);
    }
  }

  constructor(
    private configs: ConfigsService,
    private authenticationStore: Store<IAuthenticationState>,
    private influencerStore: Store<IInfluencersState>,
    private campaignStore: Store<ICampaignState>,
    private influencerSharedService: InfluencerSharedService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private templateStore: Store<ITemplateState>,
    public previewComponent: EmailEditorComponent,
    private loaderService: LoaderService,
    private _modalService: NgbModal,
    private templateApiService: TemplateApiService,
    private templateGeneratorService: TemplateGeneratorService,
    private campaignService: CampaignService,
    private segmentStore: Store<ISegmentsState>,
    private segmentService: SegmentService,
    private readonly socketService: SocketService,
    private awsService: AwsService,
    private fileUploadStore: Store<IFileUploadState>,
  ) {
    this.influencerStore.dispatch(ResetInfluencerState({
      params: {
        error: '', influencersImportTemplate: '',
        influencers: [], paginatedInfluencers: null, segments: [], influencerTypes: [], influencerBulk: null
      }
    }));
    this.route.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });

    //
    // Force campaign to reload
    this.templateGeneratorService.forceReload = true;
    this.socketService.resetSocketData();
  }

  subscribeStore() {
    this.influencerStoreSubscribe();
    this.campaignStoreSubscribe();
    this.templateStoreSubscribe();
  }

  templateStoreSubscribe() {
    this.templateStore.pipe(select(getTemplateSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
            this.toastr.success(success, 'Success');
        }
      });
  }

  influencerStoreSubscribe() {
    this.influencerStore.pipe(select(getInfluencersProjectSegment))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(segments => {
        if (segments && segments.length > 0) {
          this.segmentIsLoading = false;
          this.segmentsGlobal = segments;
          if (this.selectedInfluencer) {
            this.filteredSegments = this.segmentsGlobal.filter(item => item.influencer_type_id === this.selectedInfluencer);

            //
            // Pre select the first segment if none is selected
            if (!this.selectedSegment && this.filteredSegments?.length && this.filteredSegments[0]?.id) {
              this.selectedSegment = this.filteredSegments[0]?.id;
              this.getSegmentForUpdate();
              if (document.querySelector('.dataTables_empty')) {
                document.querySelector('.dataTables_empty').innerHTML = this.getMessage();
              }
            }
          }
        }
      });


    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        // if (data !== undefined && data !== null) {
        //   this.importProgress = {
        //     status: 'uploading',
        //     progress: data
        //   };
        //   if (data >= 100) {
        //     this.importProgress = {
        //       status: 'started',
        //       progress: 0
        //     };
        //   }
        // }
      });

    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.fileResponse && this.fileResponse.length > 0) {
          this.csvUrl = this.fileResponse[0]['url'].split("?")[0];
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(this.blobObject);
          a.download = this.fileName;
          a.click();
          // this.exportModelRef.close();
          this.csvDownloadComplete = true;
          this.exportProgress.status = 'started';
          this.exportProgress.progress = '0';
        }
      });

    this.influencerStore.pipe(select(getPaginatedInfluencers))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedInfluencers => {
          if (paginatedInfluencers && paginatedInfluencers.list.length > 0) {
            if (!this.modelOpen) {
              this.influencersLocal = paginatedInfluencers.list.map((item) => ({
                ...item,
                selected: this.getInfluencerSelectedStatus(item.id, paginatedInfluencers.paging.page)
              }));

              const nonSelectInfluencer = this.influencersLocal.filter((item) => !item.selected);
              this.masterSelected = !(nonSelectInfluencer.length > 0);
              this.influencerCount = Number(paginatedInfluencers.paging.total) - this.excludedIdList.length;
            }
          } else {
            this.influencersLocal = [];
            this.influencerCount = 0;
          }
        }
      );

    this.influencerStore.pipe(select(getInfluencerSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.delete) {
            this.delete = false;
            this.loadInfluencers();
          }
        }
      });

    this.influencerStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
          this.influencerTypes.forEach(item => {
            this.influencerTypesById[item.id] = {};
            Object.keys(item.fields).forEach(key => {
              this.influencerTypesById[item.id][key] = true;
            });
          });
        }
      });
  }

  campaignStoreSubscribe() {
    this.campaignStore.pipe(select(getCampaignError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.saveCampaignLoading = false;
          this.toastr.error(error, 'Error');
        }
      });

    this.campaignStore.pipe(select(getCampaignUpdate))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaign => {
        if (campaign) {
          this.saveCampaignLoading = false;
          if (this.handleClick) {
            this.handleClick = false;
            if (campaign.landing_page_status === 'skipped') {
              this.router.navigateByUrl(
                routes.campaignShare({
                  projectId: campaign.project_id,
                  campaignId: campaign.id
                })
              );
            } else {
              this.router.navigateByUrl(
                this.routes.campaignSelectTemplate({
                  projectId: campaign.project_id,
                  campaignId: campaign.id
                })
              );
            }
          } else if (this.saveAndExit) {
            this.saveAndExit = false;
            this.router.navigateByUrl('/' + routes.campaigns({projectId: campaign.project_id}));
          }
        }
      });

    this.campaignStore.pipe(select(getCampaign))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaign => {
        if (campaign && this.campaignGetAction) {
          this.campaignGetAction = false;
          this.campaign = campaign;
          this.templateGeneratorService.setCampaignValue(campaign);
          this.step = {
            ...this.step,
            params: {
              ...this.step.params,
              campaignId: campaign.id,
              campaignName: campaign.name
            }
          };
          if (campaign.segments && campaign.segments.length > 0) {
            const segment = campaign.segments[0];
            if (!this.selectedInfluencer) {
              this.selectedInfluencer = this.audienceType || segment.influencer_type_id;
            }
            this.filteredSegments = this.segmentsGlobal.filter(item => item.influencer_type_id === this.selectedInfluencer);
            this.selectedSegment = segment.id;
            this.getSegmentForUpdate();
          } else {
            if (!this.selectedInfluencer) {
              if (this.audienceType) {
                this.selectedInfluencer = Number(this.audienceType);
              } else if (this.influencerTypes.length > 0) {
                this.selectedInfluencer = this.influencerTypes[0].id;
              }
              // Get segments
              this.getSegments();
            }
          }
          if (!this.selectedInfluencer && this.audienceType) {
            this.selectedInfluencer = Number(this.audienceType);
          }
          this.dtTrigger.next(undefined);
        }
      });

    this.influencerStore.pipe(select(getBulkInfluencers))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerBulk => {
        if (influencerBulk) {
          if (influencerBulk !== true) {
            let errorMessage = '';
            if(this.currentUser.id === influencerBulk.data.userId) {
              if (!influencerBulk.success) {
                this.importJobId = influencerBulk.data?.jobId;
                if (influencerBulk.error) {
                  if (influencerBulk.error.name === 'AppTimeoutError') {
                    errorMessage = 'Timeout';
                  }
                  if (influencerBulk.error.name === 'AppError') {
                    errorMessage = influencerBulk.message;
                  }
                  if (influencerBulk.error.name === 'AppValidationError') {
                    errorMessage = influencerBulk.error.orgMessage || 'Invalid Records';
                    this.importData = influencerBulk.data;
                      this.showDeleteLogs();
                  }
                }
                if (errorMessage) {
                  this.toastr.error(errorMessage, 'Error');
                }
              }
            }
          }
        }
      });
  }

  subscribeToSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      async (message: any) => {
        if (message?.data?.requesterType === 'influencer_bulk_operations' && (!this.importJobId || message?.data?.id === this.importJobId)) {

          // Set job id
          this.importJobId = message.data.id;

          this.importProgress.status = message.data?.status;
          this.importProgress.progress = message?.data?.progress ? message?.data?.progress : 0;
          // adjust loading state for finished/failed
          if (['finished', 'failed'].includes(this.importProgress.status)) {
            setTimeout(() => {
              this.loaderImport.next(false);
              // Keep status
              const status: string = this.importProgress?.status;
              // Restart progress upon failed
              if (status === 'failed') {
                this.bulkStatus();
                this.importProgress = {
                  status: 'started',
                  progress: 0
                };
                this.multipleDelete = false;
                this.multipleUnsubscribe = false;
                this.multipleUpdate = false;
                this.masterSelected = false;
                this.selectAllInfluencer = false;
                this.influencerListIds = [];
                this.pageNumber = 0;
                this.includedIdList = [];
                this.excludedPageList = [];
                this.includedPageList = [];
                this.excludedIdList = [];
              } else if (status !== 'failed') {
                this.importJobId = null;
                if (status !== 'started') {
                  if (this.multipleDelete) {
                    this.toastr.success('Delete successful', 'Success');
                  }
                  if (this.multipleUpdate) {
                    this.toastr.success('Update successful', 'Success');
                  }
                  if (this.multipleUnsubscribe) {
                    this.toastr.success('Unsubscribe successful', 'Success');
                  }
                  this.pageNumber = 0;
                  this.loadInfluencers();
                  this.importProgress = {
                    status: 'started',
                    progress: 0
                  };
                  this.multipleDelete = false;
                  this.multipleUpdate = false;
                  this.multipleUnsubscribe = false;
                  this.masterSelected = false;
                  this.selectAllInfluencer = false;
                  this.influencerListIds = [];
                  this.includedIdList = [];
                  this.excludedPageList = [];
                  this.includedPageList = [];
                  this.excludedIdList = [];
                }
              }

            }, 1500);
          }
        }

        if (message?.data?.requesterType === 'influencer_export' && (!this.exportJobId || message?.data?.id === this.exportJobId) && message?.data?.uniqueImportString === this.uniqueImportString) {
          if(!this.csvDownloadComplete) {
            this.exportProgress.status = 'started';
            this.exportProgress.progress = '0';
          }
          // Set job id
          this.exportJobId = message.data.id;
          this.exportProgress.status = message.data?.result?.status;
          this.exportProgress.progress = message?.data?.result?.progress ? message?.data?.result?.progress : 0;
          // adjust loading state for finished/failed
          const status: string = this.exportProgress?.status;
          if (['finished', 'failed'].includes(this.exportProgress.status)) {
            setTimeout( async () => {
              this.loaderImport.next(false);
              // Keep status
              // Restart progress upon failed
              if (status === 'finished' && !this.exportProcess) {
                  this.exportProcess = true;
                  if (message?.data?.result?.fileUrl) {
                    this.csvUrl = message?.data?.result?.fileUrl;

                    // Download export file
                    await this.downloadExportFile();
                    this.csvDownloadComplete = true;
                    this.exportProgress.status = 'started';
                    this.exportProgress.progress = '0';
                  }
                  this.exportJobId = null;
              }
              if (status === 'failed') {
                this.exportModelRef.close();
                this.csvDownloadComplete = false;
                this.exportJobId = null;
              }
            },1500);
          }
        }


      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  async downloadExportFile() {
    const fileName = this.csvUrl.split('/').pop();
    const response = await fetch(this.csvUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.influencerStore.dispatch(InfluencersTypeList({params: {influencer_fields: true}}));
    this.loader$ = this.loaderService.loader$;
    this.getSegments();
    this.route.params.subscribe((params) => {
      this.route.queryParams.subscribe(async (queryParams) => {
        this.campId = queryParams.campId;
        this.audienceType = queryParams.audienceType;
        this.step = {title: 'Influencers', params: {projectId: params.id, campaignId: null, campaignName: null}};
        this.subscribeStore();
        this.subscribeToSocket();
        if (this.campId) {
          this.campaignGetAction = true;
          // this.campaignStore.dispatch(CampaignGet(queryParams.campId, {
          //   options: JSON.stringify({
          //     include_segments: true,
          //     include_email_status: true
          //   })
          // }));
        }
      });
    });
  }

  changeSelectedInfluencer(value) {
    this.selectedInfluencer = value;
  }
  changeSelectedSegment(value) {
    this.selectedSegment = value;
  }

  getMessage() {
    if (!this.selectedInfluencer && !this.selectedSegment) {
      return 'Please select a influencer type';
    } else if (this.selectedInfluencer && !this.selectedSegment) {
      return 'Please select a segment';
    } else {
      return `Add individual influencer or Upload them in bulk. <br>
                         You can skip this step for now, you'll have the chance to add them before launching your campaign.
                        <div>
                          <a title="Add" class="cursor-pointer" onclick="window.dispatchEvent(new CustomEvent('emptyTableCallback', {detail: 'add'}))">
                            <img class="" src="../../assets/images/add-ic.png">
                          </a>
                          <a onclick="window.dispatchEvent(new CustomEvent('emptyTableCallback', {detail: 'import'}))" class="cursor-pointer" title="Import">
                            <img class="mx-2" src="../../assets/images/export-normal.png">
                          </a>
                          <a onclick="window.dispatchEvent(new CustomEvent('emptyTableCallback', {detail: 'skip'}))" class="cursor-pointer btn btn-default btn-sm" title="Skip">
                            Skip for now
                          </a>
                        </div>`;
    }
  }
  loadInfluencers(perPage = 100, search = '', orderBy = {'column': 'updated_at', 'dir': 'desc'}) {
    if (this.selectedSegment) {
      this.influencerStore.dispatch(InfluencersListByProjectPaginate({
        params: {
          options: JSON.stringify({includePagination: true, orderBy, includeEmailOpen: true, includeEmailClick: true, includeCampaignInfluencer: true}),
          project_id: this.id,
          campaign_id: this.campId,
          ...(this.selectedInfluencer ? {influencer_type_id: this.selectedInfluencer} : {}),
          ...(this.selectedSegment ? {project_influencer_segment_id: this.selectedSegment} : {}),
          page: this.pageNumber + 1,
          perPage,
          search
        }
      }));
    } else {
      this.influencersLocal = [];
      this.influencerCount = 0;
    }
  }

  getInfluencerSelectedStatus(id, pageNumber) {
    let result;
    if (this.selectAllInfluencer) {
      if (this.excludedPageList.includes(Number(pageNumber))) {
        result = !this.excludedIdList.includes(id);
      } else {
        result = true;
      }
    } else {
      // result = this.includedPageList.includes(Number(pageNumber));
      if (!this.includedPageList.includes(Number(pageNumber))) {
        result = this.includedIdList.includes(id);
      } else {
        result = true;
      }
    }
    return result;
  }

  arrowRotate(event) {
    const target = event.target.closest('a.block-1.rotationArrow');
    const target1 = target.querySelector('.flip-arrow-ic');
    if (target1.classList.contains('rotation')) {
      target1.classList.remove('rotation');
    } else {
      target1.classList.add('rotation');
    }
  }

  getSegments() {
    if (!this.segmentIsLoading) {
      this.segmentIsLoading = true;
      const projectId = this.id;
      this.influencerStore.dispatch(InfluencersProjectSegment({projectId}));
    }
  }

  emptyMessage() {
    this.segmentError = 'Please select one segment!';
    this.toastr.error(this.segmentError, 'Error: ');
  }

  public changeStatus(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.delete = true;
        this.influencerStore.dispatch(InfluencersDelete({influencerId: id}));
      }
    });
  }

  private async saveCampaignSegment() {
    const campaign = {segments: [this.selectedSegment]};

    // this.campaignStore.dispatch(CampaignUpdate({campaign, campaignId: this.campId}));
    this.loaderService.show();
    return await this.campaignService.updateCampaign(campaign, this.campId).pipe(
      map((resp: any) => {
        if (resp.success) {
          this.loaderService.hide();
          return this.campaignStore.dispatch(CampaignUpdateSuccess({campaign: resp.data}));
        }
        this.loaderService.hide(true);
        return this.campaignStore.dispatch(CampaignUpdateError({error: this.loaderService.getErrorMessage(resp)}));
      }),
      catchError(error => {
        this.loaderService.hide(true);
        return of(this.campaignStore.dispatch(CampaignUpdateError({error: this.loaderService.getErrorMessage(error)})));
      })
    ).toPromise();
  }

  public async handleClickNext() {
    this.saveCampaignLoading = true;
    this.handleClick = true;
    await this.saveCampaignSegment();
  }

  onSaveButtonClick() {
    this.saveCampaignLoading = true;
    this.saveAndExit = true;
    this.saveCampaignSegment();
  }

  validateSave(isExit = false, showPopup = true) {
    if (!this.selectedInfluencer) {
      this.influencerError = 'Please select a influencer type!';
      this.toastr.error(this.influencerError, 'Error: ');
      return;
    }
    if (!this.selectedSegment) {
      this.emptyMessage();
      return;
    }
    if (this.influencerCount <= 0 && showPopup) {
      Swal.fire({
        title: 'You have 0 influencers to mobilize do want to skip and proceed further?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Skip',
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.value) {
          if (isExit) {
            this.onSaveButtonClick();
          } else {
            this.handleClickNext();
          }
        }
      });
      return;
    }
    if (isExit) {
      this.onSaveButtonClick();
    } else {
      this.handleClickNext();
    }
  }

  public handleClickBack() {
    if (this.campId && this.id) {
      this.router.navigate(['/projects/' + this.id + '/campaigns/create'], {queryParams: {campId: this.campId}});
    }
  }

  addInfluencer(isEdit: boolean = false, influencerId = null) {
    this.modelOpen = true;
    this.loader$ = null;
    this.editInfluencerId = influencerId;
    this.isModelClosed = false;
    const modelRef: any = this._modalService.open(!isEdit ? this.influencerAdd : this.influencerEdit, {
      centered: false,
      size: 'xl',
      keyboard: true,
      scrollable: true,
      windowClass: 'custom-modal'
    });
    modelRef.result.then((result) => {
      this.modelOpen = false;
      this.loader$ = this.loaderService.loader$;

      if (!this.editInfluencerId && !this.isModelClosed) {
        this.searchValue = '';
        // (this.datatableInstance as any).search(this.searchValue).draw();
      }
      this.loadInfluencers(this.pageLength, this.searchValue, {'column': 'updated_at', 'dir': 'desc'});
    }, (reason) => {
      this.modelOpen = false;
      this.loader$ = this.loaderService.loader$;
      this.loadInfluencers(this.pageLength, this.searchValue, {'column': 'updated_at', 'dir': 'desc'});
    });
  }

  closeInfluencer() {
    this.isModelClosed = true;
  }

  getInfluencerType(influencerTypeId) {
    const type = this.influencerTypes.find(item => item.id === influencerTypeId);
    return type ? type.name : '';
  }

  getInfluencerTypeSlug(influencerTypeId) {
    const type = this.influencerTypes.find(item => item.id === influencerTypeId);
    return type ? type.slug : '';
  }

  getInfluencerSegment(segmentId) {
    const type = this.segmentsGlobal.find(item => item.id === segmentId);
    return type ? type.name : '';
  }
  filterInfluencers(event) {
    this.influencerError = '';
    this.masterSelected = false;
    this.selectAllInfluencer = false;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.influencerListIds = [];
    this.influencerSegment.clearSelection();
    this.selectedInfluencer = null;
    this.selectedSegment = null;
    if (event) {
      this.selectedInfluencer = Number(event.value);
    }
    this.filteredSegments = [];
    if (this.selectedInfluencer) {
      this.filteredSegments = this.segmentsGlobal.filter(item => item.influencer_type_id === this.selectedInfluencer);
    }
    this.pageNumber = 0;
    this.loadInfluencers();
  }

  filterSegments(event) {
    this.segmentError = '';
    this.masterSelected = false;
    this.selectAllInfluencer = false;
    this.influencerListIds = [];
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.pageNumber = 0;
    if (this.selectedInfluencer) {
      this.selectedSegment = null;
      this.selectedInfluencerForUpdate = null;
      if (event) {
        this.selectedSegment = Number(event.value);
      }
      this.getSegmentForUpdate();
      this.loadInfluencers();
    }
  }

  importInfluencers() {
    this.loader$ = null;
    const modelRef = this._modalService.open(InfluencersImportComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.componentInstance.campId = this.campId;
    modelRef.componentInstance.influencerTypes = this.influencerTypes;
    modelRef.componentInstance.preSelectedSegmentId = this.selectedSegment;
    modelRef.componentInstance.segments = this.segmentsGlobal;
    modelRef.componentInstance.projectId = this.id;
    modelRef.componentInstance.selectedInfluencer = this.selectedInfluencer;
    modelRef.result.then((result) => {
      this.selectedSegment = result.selectedSegment;
      this.loader$ = this.loaderService.loader$;
      this.loadInfluencers();
      this.getSegments();
    }, (reason) => {
      this.loader$ = this.loaderService.loader$;
      this.loadInfluencers();
      this.getSegments();
    });
  }

  showReferralList(id: number, name: string) {
    this.loader$ = null;
    const modelRef = this._modalService.open(ReferralsListComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.componentInstance.influencerId = id;
    modelRef.componentInstance.influencerName = name;
    modelRef.componentInstance.influencerTypes = this.influencerTypes;
    modelRef.componentInstance.segmentsGlobal = this.segmentsGlobal;
    modelRef.result.then((result) => {
      this.loader$ = this.loaderService.loader$;
    }, (reason) => {
      this.loader$ = this.loaderService.loader$;
    });
  }

  manageSegments() {
    this.loader$ = null;
    const modelRef = this._modalService.open(SegmentListComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.componentInstance.projectId = this.id;
    modelRef.componentInstance.campaignId = this.campId;
    modelRef.componentInstance.selectedInfluencer = this.selectedInfluencer;
    modelRef.result.then((result) => {
      //
      // Retuned segment
      if (result?.segment?.id) {
        this.selectedSegment = result.segment.id;
      }

      this.loader$ = this.loaderService.loader$;
      this.getSegments();
      this.loadInfluencers();
    }, (reason) => {
      this.loader$ = this.loaderService.loader$;
      this.getSegments();
      this.loadInfluencers();
    });
  }

  redirectToLandingPage(url) {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  formatInsertTime(insertTime) {
    insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    if (insertTime.isValid()) {
      return insertTime.format('DD-MM-YYYY hh:mm a');
    }
    return '-';
  }

  formatMoment(insertTime) {
    insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    if (insertTime.isValid()) {
      return insertTime;
    }
    return null;
  }

  exportInfluencers() {
    const commonFields = ['name', 'description', 'pageViews', 'referred_count', 'campaign_influencer_page_url', 'campaign_influencer_share_page_url', 'public_info_update_page_url', 'source', 'email_open', 'email_click', 'email_status', 'created_at', 'updated_at', 'email_open_tracker_html', 'email_open_tracker_url', 'share_link_tracker_url', 'personalized_images'];
    const fieldsWithConditions = ['email', 'image', 'influencer_type_id', 'project_influencer_segment_id',
      'registration_url', 'company', 'first_name', 'last_name',
      'phone', 'video_url', 'booth_number', 'job_title', 'sessions',
      'custom_1', 'custom_2', 'custom_3', 'custom_4', 'custom_5',
      'custom_6', 'custom_7', 'custom_8', 'custom_9', 'custom_10'];

    this.uniqueImportString = (new Date().getTime()).toString(36);

    this.exportProcess = false;
     if (!['finished', 'failed'].includes(this.exportProgress.status)) {
      this.csvDownloadComplete = false;
    }
    let fieldsToInclude = [];
    // if (this.showAll) {
    fieldsToInclude = fieldsWithConditions.filter(fieldName => (!this.selectedInfluencer ||
      (this.selectedInfluencer && this.influencerTypesById[this.selectedInfluencer][fieldName])));
    // }
    fieldsToInclude = [...commonFields, ...fieldsToInclude];
    const filters = {
      options: JSON.stringify({includePagination: false, orderBy: {'column': 'created_at', 'dir': 'desc'}}),
      fieldsToInclude: JSON.stringify(fieldsToInclude),
      project_id: this.id,
      uniqueImportString: this.uniqueImportString,
      campaign_id: this.campId,
      ...(this.selectedInfluencer ? {influencer_type_id: this.selectedInfluencer} : {}),
      ...(this.selectedSegment ? {project_influencer_segment_id: this.selectedSegment} : {}),
      timezone: this.currentUser.timezone,
      v1: (new Date()).getTime()
    };

    const params = Object.keys(filters).map(key => `${key}=${filters[key]}`).join('&');
    if(!this.csvDownloadComplete) {
      this.exportProgress.status = 'started';
      this.exportProgress.progress = '0';
    }
    // this.exportModelRef = this._modalService.open(this.csvDownload, {
    //   centered: true,
    //   size: 'md',
    //   keyboard: true,
    // });
    this.exportModelRef.result.then((result) => {
    }, (reason) => {
    });


    this.influencerStore.dispatch(InfluencersExport({data: filters}));

    // setTimeout(() => {
    //   modelRef.close();
    // },1500);
    // window.open(`${environment.apiUrl}/api/v1/influencer/export/download?${params}`);

  }

  async sendTestEmails(email) {
    if ((this.campaign.from_email_status && this.campaign.from_email_status['verification_status'] === 'unverified') ||
      (this.campaign.email_from && this.campaign.email_from !== 'no-replay@snoball.it' && !this.campaign.from_email_status)) {
      this.toastr.warning('Please verify your email', 'Warning');
      return;
    } else {
      if (email) {
        const params = {
          campaign_id: this.campaign?.id,
          template_type_id: 2
        };
        this.templateApiService.index(params).pipe(takeUntil(this.unsubscriber)).subscribe(response => {
          if (response.body.success) {
            if (response.body.data.length > 0) {
              this.template = response.body.data[0];
              if (this.previewComponent.validateEmail(email)) {
                const param: any = {};
                param.email = email;
                param.isInfluencerEmail = true;
                param.sampleInfluencerEmail = email;
                param.additionalContent = '';
                this.templateStore.dispatch(TemplatePreview({
                  templateId: this.template.id,
                  template: param
                }));
              } else {
                this.toastr.error('Email is not valid');
              }
            } else {
              this.toastr.error('Email template not found');
            }
          }
        });
      }
    }
  }

  getCreateUpdateInfo(user) {
    if (user) {
      if ((user.hasOwnProperty('first_name') && user.first_name) || (user.hasOwnProperty('last_name') && user.last_name)) {
        return `${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`;
      } else if (user.hasOwnProperty('email') && user.email) {
        return user.email;
      }
    }
  }


  bulkStatus() {
    this.influencerStore.dispatch(InfluencersBulkStatus({importJobId: this.importJobId}));
  }

  public deleteSelectedInfluencer() {
    Swal.fire({
      title: 'Are you sure you want to delete from the whole project? this will permanently remove them from all related campaigns and clear their stats as well',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      width: '50%'
    }).then(result => {
      if (result.value) {
        let data = {};
        this.multipleDelete = true;
        this.loaderImport.next(true);
        data = {
          influencerList: !this.selectAllInfluencer ? this.includedIdList : null,
          influencerType: this.selectedInfluencer,
          influencerSegment: this.selectedSegment,
          project_id: this.id,
          selectAll: this.selectAllInfluencer,
          excludedInfluencers: this.excludedIdList,
          bulkAction: 'delete'
        };
        this.influencerStore.dispatch(InfluencersBulk({data: data}));
      }
    });
  }
  public unsubscribeSelectedInfluencer() {
    Swal.fire({
      title: 'Write a reason for unsubscribing:',
      input: 'text',
      confirmButtonText: 'Unsubscribe',
      showCancelButton: true,
      customClass: {
        popup: 'unsubscribe-pop-up'
      }
      // customClass: 'unsubscribe-pop-up'
    }).then((result) => {
      if (result.value === '' || result.value === null) {
        Swal.fire({
          title: 'You need to write reason for unsubscribing!',
          icon: 'warning',
        }).then((res) => {
          if (res.value) {
            this.unsubscribeSelectedInfluencer();
          }
        });
      }
        if (result.value) {
          let data = {};
          this.multipleUnsubscribe = true;
          this.loaderImport.next(true);
          data = {
            influencerList: !this.selectAllInfluencer ? this.includedIdList : null,
            influencerType: this.selectedInfluencer,
            influencerSegment: this.selectedSegment,
            project_id: this.id,
            selectAll: this.selectAllInfluencer,
            excludedInfluencers: this.excludedIdList,
            bulkAction: 'unsubscribe',
            reason: result.value
          };
          this.influencerStore.dispatch(InfluencersBulk({data: data}));
        }
    });
  }

  updateSelectedInfluencer() {
    Swal.fire({
      title: 'Are you sure you want to move Selected influencers to another segment?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.multipleUpdate = true;
        this.loaderImport.next(true);
        const data = {
          influencerList: !this.selectAllInfluencer ? this.includedIdList : null,
          influencerType: this.selectedInfluencer,
          influencerSegment: this.selectedSegment,
          project_id: this.id,
          selectAll: this.selectAllInfluencer,
          excludedInfluencers: this.excludedIdList,
          updateData: {project_influencer_segment_id: this.selectedInfluencerForUpdate},
          bulkAction: 'update'
        };
        this.influencerStore.dispatch(InfluencersBulk({data: data}));
      }
    });
  }


  segmentForUpdate(event) {
    if (event) {
      this.selectedInfluencerForUpdate = Number(event.value);
    }
  }

  checkUncheckAllInfluencer() {
    if (!this.masterSelected) {
      if (!this.excludedPageList.includes(this.pageNumber + 1)) {
        this.excludedPageList.push(this.pageNumber + 1);
      }
      const index = this.includedPageList.indexOf(this.pageNumber + 1);
      if (index > -1) {
        this.includedPageList.splice(index, 1);
      }
    } else {
      const index = this.excludedPageList.indexOf(this.pageNumber + 1);
      if (index > -1) {
        this.excludedPageList.splice(index, 1);
      }
      if (!this.includedPageList.includes(this.pageNumber + 1)) {
        this.includedPageList.push(this.pageNumber + 1);
      }
    }

    this.influencersLocal = this.influencersLocal.map((item) => ({
      ...item,
      selected: this.masterSelected
    }));
    this.fetchCheckedIDs();
  }

  getSegmentForUpdate() {
    this.filteredUpdateSegments = this.filteredSegments.filter(item => item.id !== this.selectedSegment);
  }

  onChangeInfluencerSelection(checked, id) {
    if (this.selectAllInfluencer) {
      if (!checked) {
        if (!this.excludedIdList.includes(id)) {
          this.excludedIdList.push(id);
          this.influencerCount -= 1;
        }
      } else {
        const index = this.excludedIdList.indexOf(id);
        if (index > -1) {
          this.excludedIdList.splice(index, 1);
          this.influencerCount += 1 ;
        }
      }
    } else {
      if (checked) {
        if (!this.includedIdList.includes(id)) {
          this.includedIdList.push(id);
        }
      } else {
        const index = this.includedIdList.indexOf(id);
        if (index > -1) {
          this.includedIdList.splice(index, 1);
        }
      }
    }

    const selectedInfluencers = this.influencersLocal.filter((item) => item.selected);
    this.masterSelected = selectedInfluencers.length === this.influencersLocal.length;
    if (!this.masterSelected && !this.excludedPageList.includes(this.pageNumber + 1)) {
      this.excludedPageList.push(this.pageNumber + 1);
    }
    if (!this.masterSelected && this.includedPageList.includes(this.pageNumber + 1)) {
      const index = this.includedPageList.indexOf(this.pageNumber + 1);
      if (index > -1) {
        this.includedPageList.splice(index, 1);
      }
    }
  }

  fetchCheckedIDs() {
    this.influencersLocal.forEach((value) => {
      if (value.selected) {
        if (this.selectAllInfluencer) {
          const index = this.excludedIdList.indexOf(value.id);
          if (index > -1) {
            this.excludedIdList.splice(index, 1);
            this.influencerCount += 1 ;
          }
        } else {
          if (!this.includedIdList.includes(value.id)) {
            this.includedIdList.push(value.id);
          }
        }
      } else {
        if (this.selectAllInfluencer) {
          if (!this.excludedIdList.includes(value.id)) {
            this.excludedIdList.push(value.id);
            this.influencerCount -= 1;
          }
        } else {
          const index = this.includedIdList.indexOf(value.id);
          if (index > -1) {
            this.includedIdList.splice(index, 1);
          }
        }
      }
    });
  }

  checkAllInfluencer() {
    this.selectAllInfluencer = true;
    this.masterSelected = true;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.checkUncheckAllInfluencer();
  }

  uncheckAllInfluencer() {
    this.selectAllInfluencer = false;
    this.masterSelected = false;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.checkUncheckAllInfluencer();
  }

  isImportDataFilled() {
    return this.importData && Object.keys(this.importData).length > 0;
  }

  importDataTableColumns() {
    return this.importData['columns'];
  }

  showDeleteLogs() {
    const modelRef = this._modalService.open(this.logsTable, {
      centered: false,
      size: 'xl',
      keyboard: false,
      backdrop: 'static',
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  getSignedUrlFromFile(file) {
    return new Promise((resolve, reject) => this.awsService.getAwsSignedUrl(file.name, file.type, true).subscribe((resp: any) => {
      resolve({
        url: resp.data.url,
        fileData: file
      });
    }, reject));
  }

  close() {
    this.influencersLocal = this.influencersLocal.map((item) => ({
      ...item,
      selected: false
    }));
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }
}
