<div class="container-fluid pt-3 new-styles">
  <div class="rightBody">
    <div class="card card-box mb-5">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          Manage Registration Platforms
        </div>
      </div>
      <div class="row m-0">
      </div>
      <div class="sticky-dropdown-custom-table register-platform-table pagination-table p-3">
        <table width="100%" class="sb-table table dataTable  no-footer nowrap" [dtTrigger]="dtTrigger" datatable
               [dtOptions]="dtOptions"
               id="dt-table">
          <thead [ngClass]="{'d-none': true}">
          <tr>
            <th class="no-sort project-image">image</th>
            <th>Name</th>
            <th>Active</th>
            <th>Webhook</th>
            <th>Auth Key</th>
            <th>Event Id</th>
            <th>Platform API</th>
            <th>Username</th>
            <th>Password</th>
            <th>Attendee Key</th>
            <th>Partner Key</th>
            <th>Speaker Key</th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="registrationPlatformList.length > 0" class="pagination-body">
          <tr *ngFor="let platform of registrationPlatformList; let i=index;" class="registration-pagination">
            <td class="w-100 name-details">
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="img-wrapper mr-3">
                    <img class="image-thumbnail" *ngIf="platform.image" [src]="platform.image" alt=""/>
                  </div>
                  <div class="pagination-name font-weight-bold">
                    {{platform.name}}
                    <ng-container *ngIf="getLastSyncTime(platform.slug)">
                      <app-info-popover container="body" info="{{getLastSyncTime(platform.slug)}}"></app-info-popover>
                    </ng-container>
                  </div>
                </div>
                <div class="widget_code pagination-status-checkbox">
                  <span class="mr-2">
                  <a *ngIf="!onChecked(i , platform.slug)"
                     (click)="onActiveChange(true, authSwapcardModel, authLineUpNinjaModel, authTerrapinSalesforceModel, authSessionBoardModel, authMapYourShowModel, authPersonifyA2ZModel, platform, i)"
                     title="Activate"
                     class="activate"><i class="fa fa-toggle-off" aria-hidden="true"></i></a>
                  <a *ngIf="onChecked(i , platform.slug)"
                     (click)="onActiveChange(false, authSwapcardModel, authLineUpNinjaModel, authTerrapinSalesforceModel, authSessionBoardModel, authMapYourShowModel, authPersonifyA2ZModel, platform, i)"
                     title="Deactivate"
                     class="deactivate"><i class="fa fa-toggle-on" aria-hidden="true"></i></a>
                </span>
                </div>
              </div>
            </td>
            <td class="webhook" *ngIf="isPlatform(platform.slug, 'webhook') && !(platform.slug === 'terrapin-salesforce' || platform.name === 'Salesforce' || platform.slug === 'map-your-show' || platform.slug === 'personify-a2z')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Webhook:
                </h6>
                <div class="webhook-link">
                  {{authProjectRegistrationPlatform[platform.slug].webhook}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'event_id')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Event Id:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].event_id}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'tenant_id')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Tenant Id:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].tenant_id}}
                </div>
              </div>
            </td>
            <td class="webhook" *ngIf="isPlatform(platform.slug, 'project_edition_id')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Project Edition ID:
                </h6>
                <div class="webhook-link">
                  {{authProjectRegistrationPlatform[platform.slug].project_edition_id}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'auth_key')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Auth Key:
                </h6>
                <div class="webhook-link">
                  {{authProjectRegistrationPlatform[platform.slug].auth_key}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'attendee_key')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Attendee Key:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].attendee_key}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'speaker_key')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Speaker Key:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].speaker_key}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'partner_key')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Partner Key:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].partner_key}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'access_token')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Access Token:
                </h6>
                <div class="webhook-link">
                  {{authProjectRegistrationPlatform[platform.slug].access_token}}
                </div>
              </div>
            </td>
            <td class="webhook" *ngIf="isPlatform(platform.slug, 'api_url')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  API URL:
                </h6>
                <div class="webhook-link">
                  {{authProjectRegistrationPlatform[platform.slug].api_url}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'show_code')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Event Show Code:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].show_code}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'username')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Username:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].username}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'password')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Password:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].password}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'email')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Contact Email:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].email}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'custom_contact_field_name')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Custom Contact Field Name:
                </h6>
                <div>
                  {{authProjectRegistrationPlatform[platform.slug].custom_contact_field_name}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'client_id')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Client Id:
                </h6>
                <div class="webhook-link">
                  {{authProjectRegistrationPlatform[platform.slug].client_id}}
                </div>
              </div>
            </td>
            <td *ngIf="isPlatform(platform.slug, 'client_secret')">
              <div class="d-flex align-items-center w-100">
                <h6 class="title mb-0 mr-2">
                  Client Secret:
                </h6>
                <div class="webhook-link">
                  {{authProjectRegistrationPlatform[platform.slug].client_secret}}
                </div>
              </div>
            </td>

            <!--            <td class="widget_code pagination-site w-100 pb-0"><a href="{{platform.website}}" target="_blank">{{platform.website}}</a></td>-->
            <td class="widget_code pagination-status w-100 pagination-site">
              <div class="d-flex align-items-center justify-content-end">
                <button class="btn btn-primary ml-auto" (click)="showPreviousSyncLogs(platform.id)" type="button">Sync Logs</button>

                <button
                  [disabled]="!isPlatformActive(platform) || (isPlatformActive(platform) && (loader$ | async) || (loaderImport$ | async))"
                  class="btn btn-primary" (click)="submitInfluencerSync(platform, i)" type="button">
                  <span *ngIf="((loader$ | async) || (loaderImport$ | async)) && this.currentSyncId === platform.id" class="btn-wrapper--icon spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                  Sync
                  <span *ngIf="((loader$ | async) || (loaderImport$ | async)) && this.currentSyncId === platform.id">

                    <span class="btn-wrapper--label">
                    {{importProgress.status === 'started' ?
                      ': started' :
                      importProgress.status === 'in-progress' ?
                        ': in-progress' :
                        importProgress.status === 'uploading' ?
                          ': uploading' :
                          ': ' + importProgress.status}}
                    </span> {{ importProgress.progress}} %

                  </span>
                </button>
              </div>

            </td>
          </tr>

          </tbody>
          <tbody *ngIf="registrationPlatformList?.length <= 0">
          <tr>
            <td colspan="8" class="no-data-available">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #authSwapcardModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex align-items-center">
      {{platformName}}
      <span class="share-link ml-2 mt-1 how-to" *ngIf="apiIntegrationGuide">
        <a [href]="shareSyncLinkUrl" target="_blank">[View HowTo]</a>
      </span>
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-wraper">
      <form [formGroup]="authSwapcardForm" (ngSubmit)="savePlatformAuth()" autocomplete="off">
        <div class="row">
          <div class="col-lg-11 col-md-12 col-ms-12">
            <div class="floating-label">
              <input [readonly]="true" formControlName="webhook" placeholder=" "
                     required="required" class="floating-input webhook-input"
                     type="text" autocomplete="off"/>
              <label>Webhook
              </label>
            </div>
          </div>
          <div class="copy-container">
            <span class="copy-clipboard cursor-pointer">
              <i title="copy" (click)="copyWebHook(authSwapcardForm.value.webhook)" class="fa fa-clone"></i>
            </span>
          </div>
          <div class="col-lg-6 col-md-12 col-ms-12">
            <div class="floating-label">
              <input formControlName="auth_key" placeholder=" "
                     required="required" class="floating-input"
                     type="text" autocomplete="off"/>
              <label>Auth Key <span class="red">*</span>
              </label>
              <div *ngIf="submitted && s.auth_key.errors" class="bar error-brd">
                <div *ngIf="s.auth_key.errors.required" class="invalid-text">Auth Key is required</div>
              </div>

            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-ms-12">
            <div class="floating-label">
              <input formControlName="event_id" placeholder=" "
                     class="floating-input"
                     type="text" autocomplete="off"/>
              <label>Event Id <span class="red">*</span>
              </label>
              <div *ngIf="submitted && s.event_id.errors" class="bar error-brd">
                <div *ngIf="s.event_id.errors.required" class="invalid-text">Event Id is required</div>
              </div>
            </div>
          </div>

          <div *ngFor="let influencerKeys of influencerKeysFormGroup.controls; let i = index" formArrayName="influencer_keys" class="col-12 p-0">
            <div [formGroupName]="i">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div class="floating-label">
                      <input class="floating-input" formControlName="name"
                             placeholder=" "
                             type="text">
                      <span class="highlight"></span>
                      <label>Influencer Key</label>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12">
                    <div class="d-flex align-items-center">
                      <div class="floating-label w-100 mr-3">
                        <i aria-hidden="true" class="fa fa-angle-down chevronSelectOptionIcon"></i>
                        <select class="floating-select chevronSelectOption" formControlName="category">
                          <option value="null" disabled selected>Select the category</option>
                          <option
                            *ngFor="let item of influencerTypes"
                            [value]="item.id">{{ item.name }}</option>
                        </select>
                        <span class="highlight"></span>
                        <label>Category</label>
                      </div>
                      <a (click)="removeInfluencerKeysButtonClick(i)"
                         *ngIf="!(influencerKeysFormGroup.controls.length === 1 || influencerKeysFormGroup.controls.length === i)"
                         class="pointer">
                        <i class="fa fa-times-circle remove-btn"></i>
                      </a>
                      <a (click)="addInfluencerKeysButtonClick()"
                         *ngIf="influencerKeysFormGroup.controls.length-1 === i"
                         class="pointer">
                        <i class="fa fa-plus-circle add-btn"></i>
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!--          <div class="col-lg-4 col-md-12 col-ms-12">-->
          <!--            <div class="floating-label">-->
          <!--              <input formControlName="attendee_key" placeholder=" "-->
          <!--                     class="floating-input"-->
          <!--                     type="text" autocomplete="off"/>-->
          <!--              <label>Attendee Key-->
          <!--              </label>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="col-lg-4 col-md-12 col-ms-12">-->
          <!--            <div class="floating-label">-->
          <!--              <input formControlName="speaker_key" placeholder=" "-->
          <!--                     class="floating-input"-->
          <!--                     type="text" autocomplete="off"/>-->
          <!--              <label>Speaker Key-->
          <!--              </label>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="col-lg-4 col-md-12 col-ms-12">-->
          <!--            <div class="floating-label">-->
          <!--              <input formControlName="partner_key" placeholder=" "-->
          <!--                     class="floating-input"-->
          <!--                     type="text" autocomplete="off"/>-->
          <!--              <label>Partner Key-->
          <!--              </label>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
            <app-creatable-select #attendeeSegment placeholder="Select segment" [selectOptions]="attendeeSegments"
                                  [hideInfo]="true" [placeholder]="'Select Attendee Segment'"
                                  [labelText]="'Select Attendee Segment'"
                                  (optionSelected)="onSegmentSelect($event, 'attendee')"
                                  [selectedValue]="selectedAttendeeSegment"></app-creatable-select>
          </div>
          <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
            <app-creatable-select #speakerSegment placeholder="Select segment" [selectOptions]="speakerSegments"
                                  [hideInfo]="true" [placeholder]="'Select Speaker Segment'"
                                  [labelText]="'Select Speaker Segment'"
                                  (optionSelected)="onSegmentSelect($event, 'speaker')"
                                  [selectedValue]="selectedSpeakerSegment"></app-creatable-select>
          </div>
          <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
            <app-creatable-select #partnerSegment placeholder="Select segment" [selectOptions]="partnerSegments"
                                  [hideInfo]="true" [placeholder]="'Select Partner Segment'"
                                  [labelText]="'Select Partner Segment'"
                                  (optionSelected)="onSegmentSelect($event, 'partner')"
                                  [selectedValue]="selectedPartnerSegment"></app-creatable-select>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-4">
            <label>How to handle records without email?</label>
            <div class="ml-2">
              <label>
                <input class="mr-1" formControlName="skip_records_without_email" type="checkbox" value="true">
                Skip
              </label>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
            <label>How to handle deletion</label>
            <div class="d-flex align-items-center url-type">
              <input class="mx-2" type="radio" formControlName="handle_deletion" value="keep_on_snoball">Keep on Snöball
              <ng-container>
                <app-info-popover container="body" info="Does not affect deleted influencers. It remains as it is." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball">Delete on Snöball
              <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, if influencer has referrals it will be opt-ed out instead." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball_forced">Delete on Snöball Forced
              <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, even if it has referrals." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="skip_on_snoball" checked="checked">Skip on Snöball
              <ng-container>
                <app-info-popover container="body" info="Excludes influencer from being added to campaigns and receiving campaign emails." ></app-info-popover>
              </ng-container>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
            <div *ngIf="!isSwapcardFormValid" class="invalid-text">Please add influencer key or category
            </div>
            <div *ngIf="isSegmentSelect" class="invalid-text">{{isSegmentSelect}}</div>
          </div>

        </div>
        <div _ngcontent-c4="" class="text-right mt-3">
          <button _ngcontent-c4="" class="btn btn-default mr-2" type="button" (click)="onCancel()">Cancel</button>
          <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Activate</button>
        </div>
      </form>
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
</ng-template>

<ng-template #authLineUpNinjaModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex align-items-center">
      {{platformName}}
      <span class="share-link ml-2 mt-1 how-to" *ngIf="apiIntegrationGuide">
        <a [href]="shareSyncLinkUrl" target="_blank">[View HowTo]</a>
      </span>
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-wraper">
      <form [formGroup]="authLineUpNinjaForm" (ngSubmit)="savePlatformAuth()" autocomplete="off">
        <div class="row">
          <div class="col-lg-11 col-md-12 col-ms-12">
            <div class="floating-label">
              <input [readonly]="true" formControlName="webhook" placeholder=" "
                     required="required" class="floating-input webhook-input"
                     type="text" autocomplete="off"/>
              <label>Webhook
              </label>
            </div>
          </div>
          <div class="copy-container">
            <span class="copy-clipboard cursor-pointer">
              <i title="copy" (click)="copyWebHook(authLineUpNinjaForm.value.webhook)" class="fa fa-clone"></i>
            </span>
          </div>

          <div class="col-lg-12 col-md-12 col-ms-12">
            <div class="floating-label">
              <input formControlName="api_url" placeholder=" "
                     required="required" class="floating-input"
                     type="text" autocomplete="off"/>
              <label>API URL
              </label>
              <div *ngIf="submitted && l.api_url.errors" class="bar error-brd">
                <div *ngIf="l.api_url.errors !== null && !l.api_url.errors.required" class="invalid-text">Please enter
                  valid API format
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-ms-12">
            <div class="floating-label">
              <input formControlName="username" placeholder=" "
                     class="floating-input"
                     type="text" autocomplete="off"/>
              <label>Username
              </label>
              <div class="bar error-brd">
                <div *ngIf="!isLienUpNinjaFormValid" class="invalid-text">Please fill all values</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-ms-12">
            <div class="floating-label">
              <input formControlName="password" placeholder=" "
                     class="floating-input"
                     type="text" autocomplete="off"/>
              <label>Password
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-ms-12">
            <app-creatable-select #speakerSegment placeholder="Select segment" [selectOptions]="speakerSegments"
                                  [hideInfo]="true" [placeholder]="'Select Speaker Segment'" [labelText]="'Select Speaker Segment'"
                                  (optionSelected)="onSegmentSelect($event, 'speaker')"
                                  [selectedValue]="selectedSpeakerSegment"></app-creatable-select>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-4">
            <label>How to handle records without email?</label>
            <div class="ml-2">
              <label>
                <input class="mr-1" formControlName="skip_records_without_email" type="checkbox" value="true">
                Skip
              </label>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
            <label>How to handle deletion</label>
            <div class="d-flex align-items-center url-type">
              <input class="mx-2" type="radio" formControlName="handle_deletion" value="keep_on_snoball">Keep on Snöball
              <ng-container>
                <app-info-popover container="body" info="Does not affect deleted influencers. It remains as it is." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball">Delete on Snöball
              <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, if influencer has referrals it will be opt-ed out instead." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball_forced">Delete on Snöball Forced
              <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, even if it has referrals." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="skip_on_snoball" checked="checked">Skip on Snöball
              <ng-container>
                <app-info-popover container="body" info="Excludes influencer from being added to campaigns and receiving campaign emails." ></app-info-popover>
              </ng-container>

            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
            <div *ngIf="isSegmentSelect" class="invalid-text">{{isSegmentSelect}}</div>
          </div>
        </div>
        <div _ngcontent-c4="" class="text-right mt-3">
          <button _ngcontent-c4="" class="btn btn-default mr-2" type="button" (click)="onCancel()">Cancel</button>
          <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Activate</button>
        </div>
      </form>
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
</ng-template>

<ng-template #authTerrapinSalesforceModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex align-items-center">
      {{platformName}}
      <span class="share-link ml-2 mt-1 how-to" *ngIf="apiIntegrationGuide">
        <a [href]="shareSyncLinkUrl" target="_blank">[View HowTo]</a>
      </span>
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-wraper">
      <form [formGroup]="authTerrapinSalesforceForm" (ngSubmit)="savePlatformAuth()" autocomplete="off">

          <div class="row">
            <div class="col-md-12 col-ms-12">
              <div class="floating-label col-lg-6 p-0">
                <input formControlName="project_edition_id" placeholder=" "
                       class="floating-input"
                       type="text" autocomplete="off"/>
                <label>Project Edition ID (Ex.: ASDF43534,FDS_a3444,fDSadd...)
                </label>

              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
              <app-creatable-select #partnerSegment placeholder="Select segment" [selectOptions]="partnerSegments"
                                    [hideInfo]="true" [placeholder]="'Select Sponsor Segment'"
                                    [labelText]="'Select Sponsor Segment'"
                                    (optionSelected)="onSalesForceSegmentSelect($event, 'sponsors')"
                                    [selectedValue]="selectedSalesForceSponsorSegment"></app-creatable-select>
            </div>
            <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
              <app-creatable-select #partnerSegment placeholder="Select segment" [selectOptions]="partnerSegments"
                                    [hideInfo]="true" [placeholder]="'Select Exhibitor Segment'"
                                    [labelText]="'Select Exhibitor Segment'"
                                    (optionSelected)="onSalesForceSegmentSelect($event, 'exhibitor')"
                                    [selectedValue]="selectedSalesForceExhibitorSegment"></app-creatable-select>
            </div>
            <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
              <app-creatable-select #speakerSegment placeholder="Select segment" [selectOptions]="speakerSegments"
                                    [hideInfo]="true" [placeholder]="'Select Speaker Segment'"
                                    [labelText]="'Select Speaker Segment'"
                                    (optionSelected)="onSalesForceSegmentSelect($event, 'speaker')"
                                    [selectedValue]="selectedSalesForceSpeakerSegment"></app-creatable-select>
            </div>


            <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
              <app-creatable-select #attendeeSegment placeholder="Select segment" [selectOptions]="attendeeSegments"
                                    [hideInfo]="true" [placeholder]="'Select Guest Segment'"
                                    [labelText]="'Select Guest Segment'"
                                    (optionSelected)="onSalesForceSegmentSelect($event, 'guest')"
                                    [selectedValue]="selectedSalesForceGuestSegment"></app-creatable-select>
            </div>

            <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
              <app-creatable-select #attendeeSegment placeholder="Select segment" [selectOptions]="attendeeSegments"
                                    [hideInfo]="true" [placeholder]="'Select Other Attendee Segment'"
                                    [labelText]="'Select Other Attendee Segment'"
                                    (optionSelected)="onSalesForceSegmentSelect($event, 'otherAttendees')"
                                    [selectedValue]="selectedSalesForceOtherAttendeeSegment"></app-creatable-select>
            </div>
            <div class="col-lg-12 col-md-12 col-ms-12 mt-4">
              <label>How to handle records without email?</label>
              <div class="ml-2">
                <label>
                  <input class="mr-1" formControlName="skip_records_without_email" type="checkbox" value="true">
                  Skip
                </label>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
              <label>How to handle deletion</label>
              <div class="d-flex align-items-center url-type">
                <input class="mx-2" type="radio" formControlName="handle_deletion" value="keep_on_snoball">Keep on Snöball
                <ng-container>
                <app-info-popover container="body" info="Does not affect deleted influencers. It remains as it is." ></app-info-popover>
              </ng-container>

                <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball">Delete on Snöball
                <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, if influencer has referrals it will be opt-ed out instead." ></app-info-popover>
              </ng-container>

                <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball_forced">Delete on Snöball Forced
                <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, even if it has referrals." ></app-info-popover>
              </ng-container>

                <input class="mx-2 " type="radio" formControlName="handle_deletion" value="skip_on_snoball" checked="checked">Skip on Snöball
                <ng-container>
                <app-info-popover container="body" info="Excludes influencer from being added to campaigns and receiving campaign emails." ></app-info-popover>
              </ng-container>

              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
              <div *ngIf="isSegmentSelect" class="invalid-text">{{isSegmentSelect}}</div>
            </div>
            <div class="col-lg-12 col-md-12 col-ms-12 mt-3 bar error-brd">
              <div *ngIf="!isTerrapinSalesforceFormValid" class="invalid-text">Please fill all values</div>
            </div>
          </div>
        <div _ngcontent-c4="" class="text-right mt-3">
          <button _ngcontent-c4="" class="btn btn-default mr-2" type="button" (click)="onCancel()">Cancel</button>
          <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Activate</button>
        </div>
      </form>
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
</ng-template>


<ng-template #authSessionBoardModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex align-items-center">
      {{platformName}}
      <span class="share-link ml-2 mt-1 how-to" *ngIf="apiIntegrationGuide">
        <a [href]="shareSyncLinkUrl" target="_blank">[View HowTo]</a>
      </span>
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-wraper">
      <form [formGroup]="authSessionBoardForm" (ngSubmit)="savePlatformAuth()" autocomplete="off">

          <div class="row">
              <div class="floating-label col-lg-6">
                <input formControlName="event_id" placeholder=" "
                       class="floating-input"
                       type="text" autocomplete="off"/>
                <label>Event ID:
                </label>
                <div class="bar error-brd">
                  <div *ngIf="!isSessionBoardFormValid" class="invalid-text">Please fill all values</div>
                </div>
              </div>

              <div class="floating-label col-lg-6">
                <input formControlName="access_token" placeholder=" "
                       class="floating-input"
                       type="text" autocomplete="off"/>
                <label>Access Token:
                </label>
                <div class="bar error-brd">
                  <div *ngIf="!isSessionBoardFormValid" class="invalid-text">Please fill all values</div>
                </div>
              </div>

           <!-- <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
              <app-creatable-select #attendeeSegment placeholder="Select segment" [selectOptions]="attendeeSegments"
                                    [hideInfo]="true" [placeholder]="'Select Attendee Segment'"
                                    [labelText]="'Select Attendee Segment'"
                                    (optionSelected)="onSegmentSelect($event, 'attendee')"
                                    [selectedValue]="selectedAttendeeSegment"></app-creatable-select>
            </div>-->
            <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
              <app-creatable-select #speakerSegment placeholder="Select segment" [selectOptions]="speakerSegments"
                                    [hideInfo]="true" [placeholder]="'Select Speaker Segment'"
                                    [labelText]="'Select Speaker Segment'"
                                    (optionSelected)="onSegmentSelect($event, 'speaker')"
                                    [selectedValue]="selectedSpeakerSegment"></app-creatable-select>
            </div>
            <div class="col-lg-4 col-md-12 col-ms-12 mt-3">
              <app-creatable-select #partnerSegment placeholder="Select segment" [selectOptions]="partnerSegments"
                                    [hideInfo]="true" [placeholder]="'Select Partner Segment'"
                                    [labelText]="'Select Partner Segment'"
                                    (optionSelected)="onSegmentSelect($event, 'partner')"
                                    [selectedValue]="selectedPartnerSegment"></app-creatable-select>
            </div>
            <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
              <div *ngIf="isSegmentSelect" class="invalid-text">{{isSegmentSelect}}</div>
            </div>
            <div class="col-lg-12 col-md-12 col-ms-12 mt-4">
              <label>How to handle records without email?</label>
              <div class="ml-2">
                <label>
                  <input class="mr-1" formControlName="skip_records_without_email" type="checkbox" value="true">
                  Skip
                </label>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
              <label>How to handle deletion</label>
              <div class="d-flex align-items-center url-type">
                <input class="mx-2" type="radio" formControlName="handle_deletion" value="keep_on_snoball">Keep on Snöball
                <ng-container>
                  <app-info-popover container="body" info="Does not affect deleted influencers. It remains as it is." ></app-info-popover>
                </ng-container>

                <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball">Delete on Snöball
                <ng-container>
                  <app-info-popover container="body" info="Clears all influencer data including stats, if influencer has referrals it will be opt-ed out instead." ></app-info-popover>
                </ng-container>

                <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball_forced">Delete on Snöball Forced
                <ng-container>
                  <app-info-popover container="body" info="Clears all influencer data including stats, even if it has referrals." ></app-info-popover>
                </ng-container>

                <input class="mx-2 " type="radio" formControlName="handle_deletion" value="skip_on_snoball" checked="checked">Skip on Snöball
                <ng-container>
                  <app-info-popover container="body" info="Excludes influencer from being added to campaigns and receiving campaign emails." ></app-info-popover>
                </ng-container>
              </div>
            </div>
          </div>
        <div _ngcontent-c4="" class="text-right mt-3">
          <button _ngcontent-c4="" class="btn btn-default mr-2" type="button" (click)="onCancel()">Cancel</button>
          <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Activate</button>
        </div>
      </form>
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
</ng-template>

<ng-template #logsTable let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Sync Logs</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="isImportDataFilled()" class="import-data-container mt-4">
      <div class="custom-table">
        <table class="sb-table table dataTable no-footer nowrap" datatable [dtOptions]="importDataTableOptions"
               id="dt1-table">
          <thead>
          <tr>
            <th *ngFor="let fieldName of importDataTableColumns()">{{fieldName}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of importData['list']">
            <td  class="delete-log" *ngFor="let field of importDataTableColumns()">{{data[field]}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <input type="hidden" ngbAutofocus>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
  </div>
</ng-template>


<ng-template #apiIntegrationGuideModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex align-items-center">
      Api Integration Guide - {{platformName}}
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="onCancelApiIntegrationModel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="apiIntegrationGuide" [innerHTML]="apiIntegrationGuide | sanitizeHtml: 'html'">
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
</ng-template>

<ng-template #authMapYourShowModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex align-items-center">
      {{platformName}}
      <span class="share-link ml-2 mt-1 how-to" *ngIf="apiIntegrationGuide">
        <a [href]="shareSyncLinkUrl" target="_blank">[View HowTo]</a>
      </span>
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-wraper">
      <form [formGroup]="authMapYourShowForm" (ngSubmit)="savePlatformAuth()" autocomplete="off">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-ms-12">
            <div class="floating-label">
              <input formControlName="show_code" placeholder=" "
                     required="required" class="floating-input"
                     type="text" autocomplete="off"/>
              <label>Event Show Code <span class="red">*</span>
              </label>
              <div *ngIf="submitted && m.show_code.errors" class="bar error-brd">
                <div *ngIf="m.show_code.errors.required" class="invalid-text">Show Code is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-ms-12 mt-3">
            <app-creatable-select #partnerSegment placeholder="Select segment" [selectOptions]="partnerSegments"
                                  [hideInfo]="true" [placeholder]="'Select Exhibitor Segment'"
                                  [labelText]="'Select Exhibitor Segment'"
                                  (optionSelected)="onMYSSegmentSelect($event, 'exhibitor')"
                                  [selectedValue]="selectedMYSExhibitorSegment"></app-creatable-select>
          </div>
          <div class="col-lg-6 col-md-6 col-ms-12 mt-3">
            <app-creatable-select #speakerSegment placeholder="Select segment" [selectOptions]="speakerSegments"
                                  [hideInfo]="true" [placeholder]="'Select Speaker Segment'"
                                  [labelText]="'Select Speaker Segment'"
                                  (optionSelected)="onMYSSegmentSelect($event, 'speaker')"
                                  [selectedValue]="selectedMYSSpeakerSegment"></app-creatable-select>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
            <div *ngIf="isSegmentSelect" class="invalid-text">{{isSegmentSelect}}</div>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-4">
            <label>How to handle records without email?</label>
            <div class="ml-2">
              <label>
                <input class="mr-1" formControlName="skip_records_without_email" type="checkbox" value="true">
                Skip
              </label>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
            <label>How to handle deletion</label>
            <div class="d-flex align-items-center url-type">
              <input class="mx-2" type="radio" formControlName="handle_deletion" value="keep_on_snoball">Keep on Snöball
              <ng-container>
                <app-info-popover container="body" info="Does not affect deleted influencers. It remains as it is." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball">Delete on Snöball
              <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, if influencer has referrals it will be opt-ed out instead." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball_forced">Delete on Snöball Forced
              <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, even if it has referrals." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="skip_on_snoball" checked="checked">Skip on Snöball
              <ng-container>
                <app-info-popover container="body" info="Excludes influencer from being added to campaigns and receiving campaign emails." ></app-info-popover>
              </ng-container>
            </div>
          </div>
        </div>
        <div _ngcontent-c4="" class="text-right mt-3">
          <button _ngcontent-c4="" class="btn btn-default mr-2" type="button" (click)="onCancel()">Cancel</button>
          <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Activate</button>
        </div>
      </form>
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
</ng-template>

<ng-template #authPersonifyA2ZModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex align-items-center">
      {{platformName}}
      <span class="share-link ml-2 mt-1 how-to" *ngIf="apiIntegrationGuide">
        <a [href]="shareSyncLinkUrl" target="_blank">[View HowTo]</a>
      </span>
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-wraper">
      <form [formGroup]="authPersonifyA2ZForm" (ngSubmit)="savePlatformAuth()" autocomplete="off">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-ms-12">
            <div class="floating-label">
              <input formControlName="event_id" placeholder=" "
                     class="floating-input"
                     type="text" autocomplete="off"/>
              <label>Event Id<span class="red">*</span></label>
              <div *ngIf="submitted && p.event_id.errors" class="bar error-brd">
                <div *ngIf="p.event_id.errors.required" class="invalid-text">Event Id is required</div>
                <div *ngIf="p.event_id.errors.pattern" class="invalid-text">Event Id must be an integer</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-ms-12">
            <div class="floating-label">
              <input formControlName="tenant_id" placeholder=" "
                     class="floating-input"
                     type="text" autocomplete="off"/>
              <label>Tenant Id<span class="red">*</span></label>
              <div *ngIf="submitted && p.tenant_id.errors" class="bar error-brd">
                <div *ngIf="p.tenant_id.errors.required" class="invalid-text">Tenant Id is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-ms-12">
            <div class="floating-label">
              <input formControlName="custom_contact_field_name" placeholder=" "
                     class="floating-input"
                     type="text" autocomplete="off"/>
              <label>Custom Contact Field Name</label>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-ms-12 mt-3">
            <app-creatable-select #partnerSegment placeholder="Select segment" [selectOptions]="partnerSegments"
                                  [hideInfo]="true" [placeholder]="'Select Exhibitor Segment'"
                                  [labelText]="'Select Exhibitor Segment'"
                                  (optionSelected)="onSegmentSelect($event, 'partner')"
                                  [selectedValue]="selectedPartnerSegment"></app-creatable-select>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
            <div *ngIf="isSegmentSelect" class="invalid-text">{{isSegmentSelect}}</div>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-4">
            <label>How to handle records without email?</label>
            <div class="ml-2">
              <label>
                <input class="mr-1" formControlName="skip_records_without_email" type="checkbox" value="true">
                Skip
              </label>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12 mt-3">
            <label>How to handle deletion</label>
            <div class="d-flex align-items-center url-type">
              <input class="mx-2" type="radio" formControlName="handle_deletion" value="keep_on_snoball">Keep on Snöball
              <ng-container>
                <app-info-popover container="body" info="Does not affect deleted influencers. It remains as it is." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball">Delete on Snöball
              <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, if influencer has referrals it will be opt-ed out instead." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="delete_on_snoball_forced">Delete on Snöball Forced
              <ng-container>
                <app-info-popover container="body" info="Clears all influencer data including stats, even if it has referrals." ></app-info-popover>
              </ng-container>

              <input class="mx-2 " type="radio" formControlName="handle_deletion" value="skip_on_snoball" checked="checked">Skip on Snöball
              <ng-container>
                <app-info-popover container="body" info="Excludes influencer from being added to campaigns and receiving campaign emails." ></app-info-popover>
              </ng-container>
            </div>
          </div>
        </div>
        <div _ngcontent-c4="" class="text-right mt-3">
          <button _ngcontent-c4="" class="btn btn-default mr-2" type="button" (click)="onCancel()">Cancel</button>
          <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Activate</button>
        </div>
      </form>
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
</ng-template>
